import { IconButton as IconButtonPrimitive } from '@radix-ui/themes'
import React from 'react'
import { cn } from '#app/utils/misc'
import { Tooltip } from './Tooltip'

// TODO: cursor pointer is not working
// TODO: add tooltip
const IconButton = React.forwardRef<
	React.ElementRef<typeof IconButtonPrimitive>,
	React.ComponentProps<typeof IconButtonPrimitive> & {
		tooltipText?: string
	}
>(({ className, tooltipText, ...props }, forwardedRef) => {
	return (
		<>
			{tooltipText ? (
				<Tooltip content={tooltipText}>
					<IconButtonPrimitive
						ref={forwardedRef}
						className={cn('cursor-pointer', className)}
						style={{
							cursor: 'pointer !important',
						}}
						{...props}
					/>
				</Tooltip>
			) : (
				<IconButtonPrimitive
					ref={forwardedRef}
					className={cn('cursor-pointer', className)}
					style={{
						cursor: 'pointer !important',
					}}
					{...props}
				/>
			)}
		</>
	)
})
IconButton.displayName = 'IconButton'

export { IconButton }
